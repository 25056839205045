import { getTitleFromPage } from '../App'
import './PreviewBlock.css'
import { getProjectPreviewImage, getProjectPreviewText} from './ProjectPreviews'
import {useNavigate} from "react-router-dom"


export function PreviewBlock(props){    
    const page = props.linkedPage
    if(getProjectPreviewImage(page)){
        return ImageBlock(props)
    }
    else{
        return TextBlock(props)
    }
}

function TextBlock(props){
    const navigate = useNavigate()

    const linkPressed = ()=>{
        navigate(props.linkedPage)
    }
    
    const page = props.linkedPage
    const infoText = getProjectPreviewText(page)

    return(
        <div className="main">
            <h2 className = "Heading" onClick={()=>{linkPressed()}}>{"/" + getTitleFromPage(page)}</h2>
            <p className = "InfoText">{infoText}</p>            
            <p className='More-Info' onClick={()=>{linkPressed()}}>More Info</p>
        </div>
    );

    
}

function ImageBlock(props){        
    const isMobile = window.innerWidth < 450
    const navigate = useNavigate()

    const linkPressed = ()=>{
        navigate(props.linkedPage)
    }

    const page = props.linkedPage
    const infoText = getProjectPreviewText(page)
    const image = getProjectPreviewImage(page)

    if(isMobile){
        return(
            <div className="image-main-mobile">               
                <div className="TextSection">
                    <h2 className = "Heading" onClick={()=>{linkPressed()}}>{"/" + getTitleFromPage(page)}</h2>
                    <div className="img-holder">
                        <img id="preview-image" src = {image} onClick={()=>{linkPressed()}} />
                    </div>                    
                    <p className = "InfoText">{infoText}</p>            
                    <p className='More-Info' onClick={()=>{linkPressed()}}>More Info</p>
                </div>
            </div>
        );
    }

    else{
        return(
            <div className="image-main">
                <div className="image-holder">
                    <img id="preview-image" src = {image} onClick={()=>{linkPressed()}} />
                </div>               
                <div className="TextSection">
                    <h2 className = "Heading" onClick={()=>{linkPressed()}}>{"/" + getTitleFromPage(page)}</h2>                    
                    <p className = "InfoText">{infoText}</p>            
                    <p className='More-Info' onClick={()=>{linkPressed()}}>More Info</p>
                </div>
            </div>
        )
    }

    

}