import {useNavigate} from 'react-router-dom'
import {Pages} from '../App'

import terminal from "../Assets/topshot-terminal.png"

export function HighFreqPost(props) {
    const navigate = useNavigate()
    const navPoint = "../" + Pages.Projects

    return (
        <div>
            <h2 className='project-back' onClick={() => { navigate(navPoint) }}>← go back</h2>
            <div className="post-container">
                <h1>High Frequency Trader</h1>

                <h3>what</h3>
                <p>In Feb 2021, I found an arbitrage(ish) of NBA Topshot, the official NBA NFT. My friends and I spent a week building a bot to take advantage of this arb and netted a few thousand $ doing it.</p>

                <h3>the arb</h3>

                <p>Topshot is basically digital trading cards of NBA highlights. Each highlight can have 100s to 10000s of cards issued. At its peak in Feb 2021, it had a sales volume of $20 million per day and high volume cards were being bought and sold every minute. </p>

                <p>We discovered that when cards were priced 20% below the lowest listing, in 9/10 cases, they would sell within 30 seconds. So we built a bot that found cards listed below that margin, bought them, then priced them up to 20% below lowest. If it didn't sell, we'd drop the price another 10% every minute and eat the cost. This happened infrequently enough that we made a profit. </p>
                <p>I discussed it in a lot more detail on my podcast <a target="_blank" href="https://podcasts.apple.com/us/podcast/age-of-information/id1555138852?i=1000514449073">here</a>.</p>
                <img src={terminal} />
                <h3>tech</h3>
                <p>NBA Topshot uses the Flow Blockchain. We built a Javascript app that ran on a Google Cloud instance w/ a backend SQL database. It ran a query to the blockchain for new events, then compared that to our DB to see if a price arb existed.</p>


                <h3>challenges</h3>
                <p>1) For each card, we needed to know the last 10 listings to determine what the price floor was and to confirm that it had high enough sales volume to be a candidate. In order to query this on the blockchain, we had to know a special ID that NBA topshot didn't make visible to users on the website. Topshot would make this mapping public when cards were being sold live on the blockchain, but for historical data we were out of luck because their blockchain explorer froze when you went past page 10 (it was clearly not O(n)).</p>

                <p>Luckily, we found a 3rd party historical price website that included the token mapping as part of the POST Request, so we scraped it to fill out our own database. As new cards were issued, our program would listen to the live blockchain to update our DB.</p>

                <p>2) We tried to automate buying and selling cards on the Topshot website using Selenium, but they had pretty serious anti-bot detection. So instead, we put Testflight iPhone apps on our phones that would send us push notifications when it saw a low price on the blockchain. We discovered that we were competing with other bots, so we had to make it take us to the exact listing with the buy button positioned right below our thumb so we could beat them.</p>

                <h3>where</h3>
                <p>The code is on Github <a target="_blank" href="https://github.com/faraza/Topshot-Moneyball">here</a> and <a target="_blank" href="https://github.com/Topshot-Moneyball/FlowQueries">here</a>.</p>

                <p>Ultimately, we stopped doing it because it became an arms race with other bot makers and this was not doing much for the good of mankind beyond making us feel smart. But it was definitely a fun way to spend a weekend. </p>


            </div>
        </div>
    );    
}
