import {useNavigate} from 'react-router-dom'
import {Pages} from '../App'

export function PodcastPost(props) {
    const navigate = useNavigate()
    const navPoint = "../" + Pages.Projects
    
    return (
        <div>
            <h2 className='project-back' onClick={() => { navigate(navPoint) }}>← go back</h2>
            <div className="post-container">
                <h1>Age of Information</h1>

                <h3>what</h3>
                <p>A friend and I started a science and technology podcast called Age of Information so we could have an excuse to talk to brilliant people about their work. A few highlights:</p>
                <p>-<a target="_blank" href="https://podcasts.apple.com/us/podcast/what-can-you-tell-me-about-software/id1555138852?i=1000519459369">How</a> Machine Learning compilers work</p>
                <p>-<a target="_blank" href="https://podcasts.apple.com/us/podcast/what-can-you-tell-me-about-software/id1555138852?i=1000519459369">Why</a> Semi Conductors are a human right</p>
                <p>-<a target="_blank" href="https://podcasts.apple.com/us/podcast/what-can-you-tell-me-about-software/id1555138852?i=1000519459369">My cohost and I</a> breakdown the tech and finance behind coinbase on the eve of their IPO</p>
                <p>-<a target="_blank" href="https://podcasts.apple.com/us/podcast/what-can-you-tell-me-about-software/id1555138852?i=1000519459369">AI</a> in computational chemistry</p>
                <p>-<a target="_blank" href="https://podcasts.apple.com/us/podcast/what-can-you-tell-me-about-software/id1555138852?i=1000519459369">I discuss</a> my high frequency trading bot</p>                
            </div>
        </div>
    );
}
