import {useNavigate} from 'react-router-dom'
import {Pages} from '../App'

import speakupGif from "../Assets/speakup-video.gif"

export function SpeakupPost(props) {
    const navigate = useNavigate()
    const navPoint = "../" + Pages.Projects
    
    return (
        <div>
            <h2 className='project-back' onClick={() => { navigate(navPoint) }}>← go back</h2>
            <div className="post-container">
                <h1>SpeakUp</h1>
                
                <h3>why</h3>
                <p>We have photo albums of our lives, but we don’t have albums of our best conversations. I think great conversations capture our relationships and points of our lives better than any other medium.</p>
                <p>The problem is, conversations are ephemeral - we rarely realize that a conversation is worth recording before it begins.</p>
                <p>That’s why I built SpeakUp.</p>
                <div className="speakup-video-container">
                    <img src={speakupGif} style={{maxHeight: "600px"}}/>
                    {/* <video className="speakup-video" src={"https://youtube.com/shorts/h4BnmHpLFM0?feature=share"} autoPlay={true} loop={true} controls style={{maxHeight: "500px"}}/> */}
                </div>
                
                <h3>how</h3>
                <p>Both parties must give their consent to be recorded. They can revoke this consent at any time. Here’s how that works: while we talk, my instance of Speakup records the words that I say. Your instance records the words that you say. If we both approve, the audio gets uploaded to the server, then merged into a single conversation. The server locks access to the file if either of us changes our mind later.</p>                

                <h3>where</h3>
                <p>If you want to see the app in action, here are the videos I submitted to the App Store for review.  <a target="_blank" href="https://www.youtube.com/watch?v=ViK4rGYFgfA">
                    Here</a> is a webcam recording of it running on my phone + me explaining how it works, and <a target="_blank" href="https://www.youtube.com/shorts/1-4rA4q6bwE">here</a> is a screen recording.</p>
                <p><a target="_blank" href="https://github.com/LetsUseSpeakUp">Here's</a> all the code on the Github.</p>
                <p>You can check out my UX designs on Figma <a target="_blank" href="https://www.figma.com/file/zBRW62duWuwGCrjuE5oQgf/SpeakUp-App?node-id=0%3A1">here</a>.</p>
                
                <h3>tech</h3>
                <p>The mobile app was ~4000 lines of code and the node Server was ~1000 lines. I built the mobile app in React Native w/ Typescript and coded up the server in Node Javascript. I hosted the server on EC2 and used a SQL server on the backend. </p>
                <p>I also built a feature that let users share audio snippets from their conversations by sharing links to a website. I coded up this website in React and put in rich video previews in iMessage w/ OpenGraph.</p>
                <p>I used Twilio and Auth0 for user accounts (the ID was a verified phone #).</p>
                <p>I initially used WebRTC for P2P phone calls, but there wasn’t an easy way to record audio (at the time), so I switched to Agora.</p>            
            </div>
        </div>
    );
    //TODO
}
