import {useNavigate} from 'react-router-dom'
import {Pages} from '../App'
import menu from '../Assets/savor-the-menu.png'
import therapist from '../Assets/therapist.png'
import joke from '../Assets/monologue-joke.png'

export function AIScreenwritingPost(props) {
    const navigate = useNavigate()
    const navPoint = "../" + Pages.Projects
    
    return (
        <div>
            <h2 className='project-back' onClick={() => {navigate(navPoint)}}>← go back</h2>
            <div className="post-container">
                <h1>GPT3 Screenwriting</h1>
                <p>I trained GPT3 on the dialogue of Aaron Sorkin, then fed it some of my lines and asked it to punch them up. Every once in a while, the results were mind-blowingly good. I co-wrote a script with GPT3 using this tool.</p>

                <h3>background</h3>
                <p>In 2021, when OpenAI released GPT3 for wide beta testing, a new genre of content started flooding YouTube - films created by AIs. These were universally terrible - while they followed the format and structure of a film, they would be unwatchable if not for the gimmick that they were written without human intervention. </p>

                <p>My overall feeling was that 100% AI written content that was legitimately high quality was a very far off goal. I still feel that way. But after extensively messing around with GPT3, I discovered that by narrowing the scope of the problem, it could produce human-level (or better) results TODAY. Consider the following output: </p>
                <img src={menu} />
                <p>There's a lot going on here that is really, really impressive. The usage of imagery, metaphor, and wordplay in "I'm savoring the menu" is way more sophisticated than I thought AI was capable of. I'm definitely cherrypicking this result, but I got enough results around this quality for it to be legitimately useful.</p>
                <h3>dialogue</h3>
                <p>GPT3 is outstanding at emulating style. In the case of writing dialogue, it is (occasionally) even better than humans. Everyone tries to ape the dialogue of writers they admire, like Tarantino or Sorkin, and most end up doing a poor imitation. Contrast that with this result:</p>
                <img src={therapist} />
                <p>The humor, rhythm, and repetition of words make it really sound like a Sorkin line. </p>
                <p>Currently, it requires a lot of curation - my hit rate is around 5% - but I have a lot of tricks up my sleeve. I think 30-50% is feasible. </p>
                <h3>future</h3>
                <p>I believe that within the next 5 years, most Hollywood writers will be using an AI assistant to help them write. In fact, I have gotten a request from a showrunner to pilot this for his next writer's room.</p>

                <p>In addition to dialogue, I also got some interesting results with writing Seth Meyers style late night monologue jokes.</p>
                <img src={joke} />
                <p>I've also experimented with outlining and breaking story, to mixed but promising results.</p>

                <h3>where</h3>
                <p>If you want to read the script I co-wrote with GPT3, you can check it out <a target="_blank" href="https://drive.google.com/file/d/135ic6b2p-wG4tFd_f_Cqwxi5LwJm__2h/">here</a>.</p>
                <p>It's a buddy cop movie in the style of Lethal Weapon set in the world of a child's imagination. I've highlighted the lines that came from GPT3. I massaged most of them a little bit - they weren't verbatim from the tool. I also generated the poster using Midjourney. The script itself is very much a first draft so don't judge it too harshly :) </p>

            </div>
        </div>
    );
}
