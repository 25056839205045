import { PreviewBlock} from './PreviewBlock';
import { Pages } from '../App';

export function AllProjectPreviews() {  
  const isMobile = window.innerWidth < 450
  const fontSize = "120%"//isMobile ? "150%" : "200%"
  return (
    <div className="Projects-Container" style={{fontSize:fontSize}}>
      <PreviewBlock linkedPage={Pages.AIScreenwriting}/>
      <PreviewBlock linkedPage={Pages.Extrasensory}/>
      <PreviewBlock linkedPage={Pages.SpeakUp} />        
      <PreviewBlock linkedPage={Pages.HighFreqTrader} />      
      <PreviewBlock linkedPage={Pages.Podcast} />
      <PreviewBlock linkedPage={Pages.BrowserCoin} /> 
      
    </div>
  );
}

/**
 * Returns null if no preview image
 * @param {*} page 
 */
 export function getProjectPreviewImage(page) {
  if (page === Pages.SpeakUp)
      return "/speakup-thumbnail_duo.png"
  if(page === Pages.HighFreqTrader){
      return "/topshot-terminal_duo.png"
  }
  if(page === Pages.BrowserCoin){
      return ""
  }
  if(page === Pages.Podcast){
    return "/ageofinfo-cover_duo.png"
  }  
  if(page === Pages.Extrasensory){
    return "/xs-chart-duotone.png"
  }

  return null
}

export function getProjectPreviewText(page) {
  if(page === Pages.AIScreenwriting){
    return `I trained GPT3 on the dialogue of Aaron Sorkin, then fed it some of my lines and asked it to punch them up. Every once in a while, the results were mind-blowingly good. I co-wrote a script with GPT3 using this tool.`
  }
  
  if (page === Pages.SpeakUp) {
      return `An app that helps you save your most meaningful phone calls by letting you record after the conversation is over.`
  }

  if(page === Pages.HighFreqTrader){
      return `I found an arbitrage(ish) of NBA Topshot, the official NBA NFT. My friends and I spent a week building a bot to take advantage of this arb and netted a few thousand $ doing it.`
  }
  
  if(page === Pages.Podcast){
    return `I started a science/tech podcast as an excuse to talk to brilliant people about their work.`
  }

  if(page === Pages.BrowserCoin){
    return `A fun little project to understand blockchain more deeply. Create your own browser-based cryptocurrency by typing in [coin name].mybrowsercoin.com. Anyone else who goes to that website will start mining on that blockchain.`      
  }

  if(page === Pages.Extrasensory){
    return `After being a life-long nail biter, sloucher, and curse word speaker, I built an app that helped me break these habits and others.`
  }
  
}
