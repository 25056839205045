import "./ExtrasensoryPost.css"
import {useNavigate} from 'react-router-dom'
import {Pages} from '../App'

import nails1 from "../Assets/xs-nails1.png"
import posture1 from "../Assets/xs-posture1.png"
import swearing1 from "../Assets/xs-swearing1.png"

import nails2 from "../Assets/xs-nails2.png"
import longNails from "../Assets/long-nails.png"

export function ExtrasensoryPost(props) {
    const navigate = useNavigate()
    const navPoint = "../" + Pages.Projects

    return (
        <div>
            <h2 className='project-back' onClick={() => { navigate(navPoint) }}>← go back</h2>
            <div className="post-container">
                <h1>Extrasensory</h1>

                <p> After being a life-long nail biter, sloucher, and speaker of curse words, I built an app that helped me break these habits and others. </p>
                <img src={longNails} />

                <h3>origin</h3>
                <p>I am devout follower of the engineering process of <a target="_blank" href="https://en.wikipedia.org/wiki/Kaizen">Kaizen</a>. To that end, I track a huge amount of data about myself (e.g. how I spend every hour of the day) in order to be more productive and ensure that I'm spending my time in ways that I find fulfilling.</p>

                <p>While effective, I realized that I wasn't recording the most important data source - my thoughts. For example, if I knew that every day around 12 PM I had a craving for junk food, I could be proactive about avoiding this and potentially identify and neutralize my trigger.</p>

                <p>So I built an Apple Watch app. Whenever I'd feel an urge, I'd tap a button on the screen. This was low enough friction that I was logging 50-100 urges per day. After two weeks of doing this, I discovered something very surprising...</p>

                <div className="multi-image-flex">
                    <img src={nails1} />
                    <img src={posture1} />
                    <img src={swearing1} />
                </div>

                <p>The act of logging my urges made them decrease!</p>

                <p>On average, it took around 100 logged urges before I was free of the habit. Some of them took more, like posture, while others took less, like swearing. </p>

                <h3>failure</h3>
                <p>Buoyed by these miraculous results, I sent the app to a bunch of my friends. But none of them experienced the same results that I did. My Mom suggested that perhaps it wasn't the actual app that made me break these habits - it was the fact that I was motivated to have my app succeed.</p>

                <p>I stopped thinking about the app for a few months. During that time, I reformed some of my bad habits like biting my nails and browsing reddit. I tried using Extrasensory again, but this time it didn't work. It seemed like my Mom's theory was correct.</p>

                <h3>hope</h3>
                <p>I've been practicing Zen/mindfulness for about 10 years, and one thing that it emphasizes is observing your thoughts and feelings without trying to change anything. Simply observing negative emotions without an agenda will cause them to dissipate.</p>

                <p>I realized that this was the difference between the first time I used Extrasensory and the second time. Originally, I wasn't trying to break any of my habits - I just wanted to collect as much data as possible. But the second time, I had an agenda.</p>

                <p>So I tried using it a third time, reframing it in the following manner: each habit was like a demon, and when I had an urge to lapse, that was the demon trying to convince me. But instead of listening to it, I could just press the "urge" button in Extrasensory to give it a whack. Don't worry about breaking the habit - just focus on whacking the demon as much as possible.</p>

                <p>Since then, I've gotten promising results:</p>
                <img src={nails2} />

                <h3>future</h3>
                <p>A big issue with Extrasensory is that it's an "eat your vegetables" app. If I want to get other people to use it, I need to make it fun. I think the "kill your demons" framing can help with this. It's a game of trying to give your demon as many whacks as possible and maximize that score. </p>

                <p>That said, I'm still not sure that this app would get traction. Convincing other people to use it has been incredibly difficult, and when I do get users, churn is high because it requires a lot of experimentation to get results.</p>

                <p>Most likely, this app will simply be something that helps me become my best self. If anyone else is interested to try it out, I will gladly share it with them. I feel good about that outcome. </p>

                <h3>where</h3>
                <p><a target="_blank" href="https://github.com/faraza/Extrasensory">Here's</a> the Github.</p>

                <p>Hit me up if you want a link to the Testflight and if you have an iPhone (and preferably an Apple Watch).</p>


            </div>
        </div>
    );
}
