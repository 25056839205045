import { Pages } from './App';
import {useNavigate} from "react-router-dom"
import './NavBar.css'

export function NavBar(props) {
  const pageChanged = props.pageChanged;
  const selectedPage = props.selectedPage;

  const navigate = useNavigate()

  const isMobile = window.innerWidth < 450

  return (
    <div className=  "Nav-Bar-Container">
      <h1 className='Site-Title'>Faraz's Digital Patio</h1>
      <div className={isMobile ? "Nav-Bar-Mobile" : "Nav-Bar"}>
        <h2 className="About-Button" onClick={() => { navigate(Pages.About) }}>about</h2>
        <h2 className="Projects-Button" onClick={() => { navigate(Pages.Projects) }}>projects</h2>
      </div>
      <div className="Divider-Line"></div>
    </div>
  );
}
