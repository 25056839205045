import {useNavigate} from 'react-router-dom'
import {Pages} from '../App'

export function BrowsercoinPost(props) {
    const navigate = useNavigate()
    const navPoint = "../" + Pages.Projects
    
    return (
        <div>
            <h2 className='project-back' onClick={() => { navigate(navPoint) }}>← go back</h2>
            <div className="post-container">
                <h1>Browsercoin</h1>

                <h3>what</h3>

                <p>Browsercoin is a cryptocurrency/blockchain that runs entirely in a web browser. To create a new coin, you type in [coin name].mybrowsercoin.com and you will create that blockchain and start mining as long as the page remains open on your browser. If anyone goes to that website, they will also become miners. If everyone closes the website, the blockchain will cease to exist. </p>

                <p>...At least, that's what it will do if I ever finish it. I got it running on my local network but haven't put in a frontend or deployed to the web. Maybe I will at some point (but probably not).</p>

                <h3>why</h3>

                <p>For a large number of reasons (that I explain in the Github readme if you're interested) any coin created this way would be totally worthless. My main goal was to create a fun project that helped me understand Bitcoin and blockchain at a technical level. I definitely feel like I accomplished this - I was successfully able to code up Satoshi Nakamoto's Whitepaper in javascript and got it running across multiple computers on my local network.</p>

                <h3>tech</h3>
                <p>There were two main components to this: 1) Peer-to-peer networking and 2) the Blockchain/Proof of Work logic.</p>

                <p>When the app attempts to connect to a new blockchain, it must join a special server called a Signal Server that broadcasts to everyone else that it is a new member. It then forms direct connections to all of the other peers (or some subset of them when you have large scale) who send it the latest state of the blockchain. It's important that you get multiple peers to send you the state of the blockchain so you can ignore malicious actors through the Longest Chain Rule. I ran my Signal Server on Heroku by deploying Mafintosh's great <a target="_blank" href="https://github.com/mafintosh/signalhub">library</a> and used Feross's excellent p2p libary <a target="_blank" href="https://github.com/feross/simple-peer">Simple Peer</a>.</p>

                <p>I coded this up in Typescript. In fact, this was the first real project I ever built in Javascript or Typescript, so getting better at that was a secondary goal for me.</p>

                <h3>where</h3>
                <p>You can check out the Github <a target="_blank" href="https://github.com/faraza/browsercoin">here</a></p>


            </div>
        </div>
    );
}
