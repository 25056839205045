import './AboutPage.css'

import {Link} from 'react-router-dom'

export function AboutPage() {
  return (
    <div className="About-Container">
      <div className="Info-Container">
          <h1>Hey I'm Faraz.</h1>
          <p>I'm a coder, builder, and screenwriter.</p>
          <p className="about-down-arrow">↓</p>
          <div className="about-image-holder">
            <img src="/about-image-nobg_duo.png"/>
          </div>          
          <p>From 2015 to 2021, I was the Head of Software at <a href="https://sprintray.com">SprintRay</a>.
            <br/> <br/>From 2021 to 2022, I was an indie builder<sup>1</sup> & screenwriter<sup>2</sup>.
            <br/><br/> 
            You can find me on <a href="https://www.linkedin.com/in/faraz-abidi-2260b2167/">LinkedIn</a>, <a href="https://twitter.com/fzfromcupertino">
              Twitter</a>, and <a href="https://github.com/faraza">Github</a>.
            <br/><br/><a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ">Here's</a> my favorite thing on the internet.
            <br/><br/>1. Check out my <Link to="/projects">projects page</Link>.
            <br/>2. I wrote a spec script that got optioned and developed a few assignments.
      
          </p>
            
        </div>        
      
      <div className="Image-And-Info-Container">
        
        
      </div>            
    </div>
  );
}