import { useEffect, useState } from 'react';
import { AboutPage } from './AboutPage';
import './App.css';
import { NavBar } from './NavBar';
import { AllProjectPreviews } from './ProjectPreview/ProjectPreviews';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { SpeakupPost } from './ProjectPosts/SpeakupPost';
import { HighFreqPost } from './ProjectPosts/HighFreqPost';
import { BrowsercoinPost } from './ProjectPosts/BrowsercoinPost';
import { PodcastPost } from './ProjectPosts/PodcastPost';
import { ExtrasensoryPost } from './ProjectPosts/ExtrasensoryPost';
import { AIScreenwritingPost } from './ProjectPosts/AIScreenwritingPost';
import { useLocation } from "react-router";


function App() { 
  useEffect(() => {
    document.title = `Faraz's Digital Patio`;
  });

  return (
      <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Root/>}>
            <Route path ="" element={<AboutPage/>}/>
            <Route path ="*" element={<AboutPage/>}/>
            <Route path ={Pages.About} element={<AboutPage/>}/>
            <Route path={Pages.Projects} element={<ProjectsPage/>}/>                                                  
            <Route path={Pages.HighFreqTrader} element={<HighFreqPost/>}/>
            <Route path={Pages.BrowserCoin} element={<BrowsercoinPost/>}/>
            <Route path={Pages.Podcast} element={<PodcastPost/>}/>
            <Route path={Pages.SpeakUp} element={<SpeakupPost/>}/>
            <Route path={Pages.Extrasensory} element={<ExtrasensoryPost/>}/>
            <Route path={Pages.AIScreenwriting} element={<AIScreenwritingPost/>}/>
          </Route>
        </Routes>
        </ScrollToTop>
      </BrowserRouter>
  );
}

const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>
};


function Root(){
  return(
    <div>
      <NavBar />
      <Outlet/>
    </div>
  )
}


function ProjectsPage(){
  return(
    <div>
      <AllProjectPreviews/>
    </div>
  )
}

export const Pages = {
  Projects: "projects",
  About: "about",
  SpeakUp: "/speakup",
  BrowserCoin: "/browsercoin",
  HighFreqTrader: "/highfrequencytrader",
  Extrasensory: "/extrasensory",
  Podcast: "/podcast",
  AIScreenwriting: "/gpt3screenwriting"
}

export function getTitleFromPage(page){
  if(page === Pages.Projects) return "Projects"
  if(page === Pages.About) return "About"
  if(page === Pages.SpeakUp) return "SpeakUp"
  if(page === Pages.BrowserCoin) return "Browsercoin (WIP)"
  if(page === Pages.HighFreqTrader) return "High Frequency Trader"
  if(page === Pages.Extrasensory) return "Extrasensory"
  if(page === Pages.Podcast) return "Age of Information"
  if(page === Pages.AIScreenwriting) return "GPT3 Screenwriting"
  return "ERROR"
}


export default App;
